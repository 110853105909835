/* 공통 컴포넌트 스타일 */
/* Buttons-Main-Filled-1 */
/* Buttons-Main-Filled-2 */
/* Buttons-Main-Outlined */
.buttons-main-filled-1,
.buttons-main-outlined {
  width: 100%;
  border-radius: 20px;
  font-weight: 700;
}
.buttons-main-filled-2 {
  border-radius: 20px;
  font-weight: 700;
}
.buttons-main-outlined {
  border: 2px solid;
}
/* Buttons-Sub-Outlined */
.buttons-sub-outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}
.buttons-sub-outlined:hover,
.buttons-sub-outlined:active {
  background-color: var(--gray10);
}
/* Buttons-Sub-Filled */
.buttons-sub-filled {
  width: auto;
  border-radius: 100px;
  font-weight: 700;
}

/* Textfield-Main */
.textfield-main {
  position: relative;
  width: 100%;
  padding: 18px 16px 18px 48px;
  border-radius: 16px;
  outline: none;
}
.textfield-main::placeholder {
  color: var(--gray50);
}

/* 페이지 전환 애니메이션 */
.page-enter {
  transform: translateX(100%);
}
.page-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.page-exit {
  transform: translateX(0);
}
.page-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}

.page-reverse-enter {
  transform: translateX(-100%);
}
.page-reverse-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.page-reverse-exit {
  transform: translateX(0);
}
.page-reverse-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms;
}

/* 테두리 스타일 */
.border-gradient {
  position: relative;
  border-radius: 12px; /* 반경 설정 */
  z-index: 0;
  background-color: #c0ddfe;
}
.border-gradient:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  padding: 3px; /* 테두리 두께 */
  background: linear-gradient(180deg, #020092, #40a0ff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* 문장 순서화 드래그 요소 스타일 */
.draggable-item {
  display: flex;
  width: 100%;
  border-radius: 20px;
  box-shadow: 2px 2px 12px 0px #00000029;
}
.dragging {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  transition: all 0.2 ease;
  opacity: 0.8;
}
.draggable-item:first-of-type > div:first-of-type,
.draggable-item.first-item > div:first-of-type {
  border-top-left-radius: 20px;
}
.draggable-item:first-of-type > div:last-of-type,
.draggable-item.first-item > div:last-of-type {
  border-top-right-radius: 20px;
}
.draggable-item:last-of-type > div:first-of-type,
.draggable-item.last-item > div:first-of-type {
  border-bottom-left-radius: 20px;
}
.draggable-item:last-of-type > div:last-of-type,
.draggable-item.last-item > div:last-of-type {
  border-bottom-right-radius: 20px;
}

/* 가로세로퀴즈 메인화면 스타일 */
.stars-wrapper {
  position: fixed;
  pointer-events: none;
  width: 100vw;
  height: 100dvh;
  /* background: linear-gradient(#16161d, #1f1f3a, #3b2f4a); */
  background: linear-gradient(180deg, #1b2b70 0%, #3835c2 50.5%, #3353d6 100%);
  overflow: hidden;
}
.stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: twinkle var(--twinkle-duration) ease-in-out infinite;
}
.stars:nth-child(2) {
  animation-delay: calc(var(--twinkle-duration) * -0.33);
}
.stars:nth-child(3) {
  animation-delay: calc(var(--twinkle-duration) * -0.66);
}
.star {
  fill: white;
}
.star:nth-child(3n) {
  opacity: 0.8;
}
.star:nth-child(7n) {
  opacity: 0.6;
}
.star:nth-child(13n) {
  opacity: 0.4;
}
.star:nth-child(19n) {
  opacity: 0.2;
}
.comet {
  transform-origin: center center;
  animation: comet 10s linear infinite;
}
.comet-b {
  animation-delay: -3.3s;
}
.comet-c {
  animation-delay: -5s;
}
@keyframes twinkle {
  25% {
    opacity: 0;
  }
}
@keyframes comet {
  0%,
  40% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60%,
  100% {
    transform: translateX(-100vmax);
    opacity: 0;
  }
}
